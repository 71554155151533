import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Image from "../assets/chartImg.png";
import { useNavigate } from "react-router-dom";

const CompoundIntrest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navi = useNavigate();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <section className="bg-[#F3F1FF] min-h-screen flex justify-center">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[32px] py-8 px-4">
          <div>
            <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
              Nutze den Zinseszins: Mit 200€ im Monat zum Millionär.
            </h2>
            <div className="mt-[32px] space-y-[20px]">
                <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                  <strong className="font-medium text-[#7C4EFB]">Investiere am Aktienmarkt. </strong>
                  Globale Aktien erzielten in den letzten 20 Jahren eine durchschnittliche Rendite von 7% pro Jahr nach Kosten. Pensionflow macht es dir leicht: Wähle aus drei renditestarken <a href="/anlagestrategien" className="text-[#7C4EFB]" style={{ cursor: 'pointer' }}>Anlagestrategien</a>, ohne dich durch tausende ETFs kämpfen zu müssen.
                </p>
                <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                  <strong className="font-medium text-[#7C4EFB]">Fange früh an und bleibe am Ball. </strong>
                  Der Zinseszinseffekt tritt ein, wenn die Zinsen deiner Geldanlage reinvestiert werden. Langfristig kann so aus einem kleinen Betrag eine große Summe entstehen.
                </p>{" "}
                <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                  <strong className="font-medium text-[#7C4EFB]">Keine Abgeltungsteuer. </strong>
                  Das jährliche Rebalancing kontrolliert Risiken und schafft Chancen auf Zusatzrenditen. Dank staatlicher Förderung zahlst du keine Abgeltungsteuer, wodurch der Zinseszinseffekt verstärkt wird.
                </p>
            </div>
          </div>
          <div className="w-full flex flex-col">
              <img src={Image} className="w-full ms:w-48 lg:w-[650px]" style={{ objectFit: 'contain' }} alt="Zinseszins durch langfristiges ETF-Sparen" loading="lazy"/>
            <p
              onClick={openModal}
              className="text-[#6c757d] sm:leading-7 font-normal md:text-[16px] text-[14px] leading-6 mt-[15px]"
              style={{ cursor: 'pointer' }}
            >
              Annahmen
            </p>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-16">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Annahmen zur Berechnung
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 whitespace-pre-line">
                      Die Berechnung basiert auf vereinfachten Annahmen.
                      <br /><br />
                      Annahme einer konstanten jährlichen Rendite von 7%. Ein ähnliches Portfolio wie das von pensionflow, mit einer 100%-Aktienallokation (z.B. Weltportfolio Zielorientiert), erzielte in den letzten 30 Jahren ab 1994 eine durchschnittliche Rendite von etwa 7% pro Jahr nach Kosten. Das erwartete Guthaben ist ohne Berücksichtigung etwaiger Steuern angegeben.
                      <br /><br />
                      <strong>Disclaimer:</strong><br />
                      Alle Angaben sind ohne Gewähr. Trotz sorgfältiger Erstellung des Charts kann pensionflow keine Haftung für die Richtigkeit der Ergebnisse übernehmen. Letztere stellen keine Grundlage für Rechtsansprüche dar.<br />
                      Kapitalanlagen bergen zudem Risiken. Der Wert kann sowohl fallen als auch steigen, und es kann zu Verlusten des eingesetzten Kapitals kommen. Frühere Wertentwicklungen oder Prognosen sind kein verlässlicher Indikator für die zukünftige Wertentwicklung.
                    </p>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                      style={{ backgroundColor: "rgba(0,0,0,0.1)", color: 'rgb(0,0,0)' }}
                    >
                      Schließen
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  );
};

export default CompoundIntrest;
