import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";
import CloseIcon from '../assets/cross.png';
import QR_Code from '../assets/qr-code.png';

interface GetAppPopUpProps {
    isOpen: boolean;
    closeModal: () => void;
}

const GetAppPopUp: React.FC<GetAppPopUpProps> = ({ isOpen, closeModal }) => {

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <DialogPanel className="w-full max-w-xl transform rounded-2xl bg-white p-6 shadow-xl transition-all flex flex-col gap-5">
                    <img onClick={closeModal} src={CloseIcon} style={{ width: 20, height: 20, alignSelf: 'flex-end', opacity: 0.2, cursor: 'pointer' }} loading="lazy"/>

                    <DialogTitle className="text-4xl font-black text-left">
                        Hol dir die App
                    </DialogTitle>

                    <div className="grid grid-cols-2">
                        <div className="">
                            <img src={QR_Code} className="w-10/12" alt="Pensionflow App herunterladen" loading="lazy"/>
                        </div>
                        <div className="">
                        <p className="text-left text-xl">
                            <strong className="opacity-100">Scanne den QR-Code</strong>
                            <span className="opacity-50"> und starte deine Altersvorsorge mit Pensionflow – einfach, verständlich und sicher.</span>
                        </p>
                        </div>
                    </div>
                    </DialogPanel>
                </TransitionChild>
                </div>
            </div>
            </Dialog>
        </Transition>
    )
}

export default GetAppPopUp
