import { useRef } from "react";
import RetirementPlanning from "../components/RetirementPlanning";
import Banner from "../components/banner";
import CompoundIntrest from "../components/compoundIntrest";
import ETF from "../components/etf";
import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import PensionGap from "../components/pensionGap";
import PensionGapSection from "../components/pesnionGapSection";
import Planning from "../components/planning";
import ShortInstructions from "../components/shortInstructions";
import StrongPartner from "../components/strongPartner";


function Home(): JSX.Element {
  const bottomRef = useRef<HTMLDivElement>(null);


  return (
    <div>
      <Navbar isOnHome={true} />
      <Banner />
      <RetirementPlanning />
      <PensionGap />
      <PensionGapSection />
      <CompoundIntrest />
      <ETF />
      <StrongPartner />

      {/* Show ShortInstructions on larger screens */}
      <div className="hidden lg:block">
        <ShortInstructions />
      </div>

      {/* Show Planning on smaller screens */}
      <div className="block lg:hidden">
        <Planning />
      </div>
      
      <Faq ref={bottomRef} />
      <Footer />

    </div>
  );
}

export default Home;
