
function QrCodeComponent({ setIsModalOpen }: any ) {
    const openModal = () => setIsModalOpen(true);

    return (
        <div style={{ backgroundColor: 'black', borderRadius: 5, padding: 5, display: 'flex', flexDirection: 'column', gap: 5, height: 135, marginTop: 25 }}>
            <p style={{ fontSize: 12, textAlign: 'center', color: 'white' }}>Hol dir die App</p>
            <img
                src={require('../assets/qr-code.png')}
                alt="Pensionflow App herunterladen"
                loading="lazy"
                width="100"
                height="100"
                style={{ width: 100, height: 100 }}
                onClick={openModal}
            />
        </div>
    )
}

export default QrCodeComponent