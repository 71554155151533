import Image from "../assets/chart.png";
const PensionGap = () => {
  return (
    <section className=" bg-[#F3F1FF] min-h-screen flex justify-center" >
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[32px] items-center py-8 px-4">
          <div className="flex flex-col justify-center gap-10">
            <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px] ">
              Was ist eigentlich die Rentenlücke?
            </h2>
            <img src={Image} alt="Rentenlücke berechnen und schließen" loading="lazy"/>
          </div>
          <div className="space-y-5">
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">Definition. </strong> 
              Die Rentenlücke ist der Unterschied zwischen dem Gehalt als Berufstätiger und der Höhe der gesetzlichen Rente.
            </p>
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">Wie viel Geld brauchst du im Alter? </strong>
              Eine Faustregel besagt, dass du als Rentner monatlich rund 80% deines letzten Nettolohns benötigst.
            </p>{" "}
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">Langlebigkeitsrisiko. </strong>
              Das Risiko, länger zu leben als erwartet und nicht genug Kapital für die Rente zu haben. Bei Pensionflow kannst du neben einer vollständigen Auszahlung deines Guthabens auch eine lebenslange Rente wählen – so ist dein Einkommen bis zum Lebensende gesichert.            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PensionGap;
