import React from 'react';

interface RenditeRisikoCompProps {
    dots: string[];
    rendite: string;
}

const RenditeRisikoComp: React.FC<RenditeRisikoCompProps> = ({ dots, rendite }) => {
    return (
        <div className="flex flex-row gap-2 justify-center">
            <div className='border-2 border-[#7C4EFB] p-1 rounded-sm flex'>
                <div className="p-1 text-center flex items-center gap-2">
                    <span className="text-black text-xs">Renditeerwartung: {rendite}%</span>
                </div>
                <span>|</span>
                <div className="p-1 text-center flex items-center gap-2">
                    <span className="text-black text-xs">Risiko: </span>
                    {dots.map((dot, index) => (
                        <img src={dot} key={index} alt="risk-dot" width={8} height={8} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RenditeRisikoComp;
