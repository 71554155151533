import { post } from "@aws-amplify/api";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";


interface EmailFormPopUpProps {
    isOpen: boolean; 
    setIsOpen: any;
}

const EmailFormPopUp = ({ isOpen, setIsOpen }: EmailFormPopUpProps) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [message, setMessage] = useState("");

    const closeModal = () => setIsOpen(false);

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Simple email validation (you can use more complex validation as needed)
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        try {
            const params = {
                firstName: firstName + lastname,
                email: email,
                message: message,
                clientNr: "",
                image: "",
            };

            const restOperation = post({
                apiName: "supportEmailAPI",
                path: "/send-support-email",
                options: {
                    body: {
                        ...params
                    }
                }
            });

            const {body} = await restOperation.response;
            const response = await body.json();

            console.log(response);
        }catch (error) {
            console.log('Error sending email: ', error);
            setError("Fehler beim Senden der E-Mail. Bitte versuchen Sie es später erneut.");
        }
        
        // Reset the state and close the modal
        setEmail("");
        setError("");
        setIsOpen(false);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-xl transform overflow-hidden rounded-2xl bg-white py-10 px-6 text-left align-middle shadow-xl transition-all mt-16">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Schreib uns eine Nachricht!
                                </Dialog.Title>
                                <form onSubmit={handleSubmit} className="mt-4">
                                    <div className="space-y-4 flex flex-col">
                                        <input
                                            type="email"
                                            className="w-full lg:w-[500px] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="E-Mail"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <div className="flex gap-5">
                                            <input
                                                type="name"
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Vorname"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <input
                                                type="name"
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Nachname"
                                                value={lastname}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                        <textarea
                                            placeholder="Dein Anliegen..."
                                            id="about"
                                            name="about"
                                            rows={5}
                                            maxLength={1500}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="w-full lg:w-[500px] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                        {error && <p className="text-red-500 text-sm">{error}</p>}

                                        <p className="text-sm font-medium leading-6 text-gray-400">Hiermit akzeptiere ich die <a className="underline" href="/datenschutz">Datenschutzbestimmungen</a>.</p>
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-5 mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-black hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Abbrechen
                                        </button>
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-md border bg-[#7C4EFB] px-4 py-2 text-sm font-medium text-white hover:bg-[#6d41fc] focus:outline-none focus-visible:ring-2 focus-visible:ring-[#6d41fc] focus-visible:ring-offset-2"
                                        >
                                            Senden
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EmailFormPopUp;
