import CheckIcon from '../assets/check.png';


const BenefitsCard = () => {
    return (
        <>
        <div className="hidden bg-white h-[80px] md:flex flex-row items-center justify-between p-5 shadow-md mt-10 md:px-10 lg:px-20 xl:px-24 2xl:px-48 mb-20">
            <div className='flex flex-row items-center justify-center gap-2'>
                <img src={CheckIcon} width={20} alt='Vorteile Rentenversicherung' loading='lazy'/>
                <p className='sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6'>Keine Abschlussgebühren</p>
            </div>
            <div className='flex flex-row items-center justify-center gap-2'>
                <img src={CheckIcon} width={20} alt='Vorteile Rentenversicherung' loading='lazy'/>
                <p className='sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6'>Jederzeit ein- und auszahlen</p>
            </div>
            <div className='flex flex-row items-center justify-center gap-2'>
                <img src={CheckIcon} width={20} alt='Vorteile Rentenversicherung' loading='lazy'/>
                <p className='sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6'>Jederzeit kostenlos kündigen</p>
            </div>
        </div>
        <div className="bg-white flex md:hidden flex-col justify-center shadow-md m-5 p-5 rounded-lg gap-2">
            <div className='flex flex-row items-center gap-2'>
                <img src={CheckIcon} width={20} alt='Vorteile Rentenversicherung' loading='lazy'/>
                <p className='sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6'>Keine Abschlussgebühren</p>
            </div>
            <div className='flex flex-row items-center gap-2'>
                <img src={CheckIcon} width={20} alt='Vorteile Rentenversicherung' loading='lazy'/>
                <p className='sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6'>Jederzeit ein- und auszahlen</p>
            </div>
            <div className='flex flex-row items-center gap-2'>
                <img src={CheckIcon} width={20} alt='Vorteile Rentenversicherung' loading='lazy'/>
                <p className='sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6'>Jederzeit kostenlos kündigen</p>
            </div>
        </div>
        </>
    )
}

export default BenefitsCard
