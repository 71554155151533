import Image from "../assets/retirement-planning.png";
const RetirementPlanning = () => {
  return (
    <div className="container min-h-screen flex justify-center">
      <section className="grid grid-cols-1 md:grid-cols-2 gap-[32px] items-center py-8 px-4">
        <div className="">
          <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
            Nimm deine Altersvorsorge in die eigene Hand.
          </h2>
          <div className="mt-[32px] space-y-[20px]">
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">
              Das gesetzliche Rentenniveau liegt bei 48 Prozent und sinkt.
              </strong>{" "}
              Ein Rentner mit 45 Beitragsjahren erhält weniger als die Hälfte seines Durchschnittseinkommens. 
              Mehr als die Hälfte der gesetzlichen Renten liegt unter 1.100 € pro Monat.
            </p>
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">3-Säulenmodell. </strong>
              Der Staat unterstützt die Altersvorsorge durch gezielte Förderung. Um die dritte Säule musst du dich allerdings selbst kümmern.
            </p>{" "}
            <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
              <strong className="font-medium text-[#7C4EFB]">Der Staat fördert die private Altersvorsorge. </strong>
              Die ETF-Rentenversicherung von Pensionflow bietet dir Steuervorteile, die ein herkömmlicher 
              ETF-Sparplan nicht hat – plus lebenslange Rente und Unterstützung im Pflegefall.
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img className="md:w-[450px]" src={Image} alt="Die drei Säulen der Altersvorsorge" loading="lazy"/>
        </div>
      </section>
    </div>
  );
};

export default RetirementPlanning;
