import { useState } from 'react';
import PhoneScreen from '../assets/screenShotIntructions.png';
import GetAppPopUp from './getAppPopUp';

const ShortInstructions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="container min-h-screen flex justify-center py-5 px-4">
      <div className="container">
        <section className='instructionsBannerCont'>
            <div className='flex gap-20 justify-center items-center'>
                <div>
                    <p className='text-4xl font-black text-white'>In unter 10 Minuten mit</p>
                    <p className='text-4xl font-black text-[#4C39EE]'>der Altersvorsorge starten</p>
                </div>
                <img 
                    src={PhoneScreen}
                    width={250}
                    alt='Altersrente mit ETFs aufbauen'
                    loading='lazy'
                />
            </div>
            <div className='flex bg-[#F3F1FF] w-full p-6 justify-between items-center rounded-lg'>
                <p className='text-xl text-black font-bold'><strong className='text-3xl text-[#4C39EE] font-black'>1.</strong> App herunterladen</p>
                <p className='text-xl text-black font-bold'><strong className='text-3xl text-[#4C39EE] font-black'>2.</strong> Registrieren und identifizieren</p>
                <p className='text-xl text-black font-bold'><strong className='text-3xl text-[#4C39EE] font-black'>3.</strong> Einfach investieren</p>
                <div className='bg-[#6c757d] p-3 rounded-lg' onClick={openModal}>
                    <p className='text-white font-black cursor-pointer'>Jetzt starten</p>
                </div>
            </div>
        </section>
      </div>
      <GetAppPopUp isOpen={isModalOpen} closeModal={closeModal}/>
    </div>
  )
}

export default ShortInstructions
