import Footer from "../components/footer";
import Navbar from "../components/navbar";

function Impressum() {
  return (
    <div>
      <Navbar isOnHome={false} />

      <section className="bg-[#F3F1FF] min-h-screen flex justify-center">
          <div className="container">
            <div className="py-5 px-4">
                <h1 className="text-3xl font-bold mb-4">Impressum</h1>
                <p>Pensionflow GmbH</p>
                <p>Taunustor 3</p>
                <p>60310 Frankfurt am Main</p>
                <br />
                <p>Registergericht: Amtsgericht Frankfurt am Main HRB 135514</p>
                <p>USt-IdNr.: DE369958168</p>
                <p>Geschäftsführerin: Anabel van Heusden</p>
                <br />
                <p>E-Mail: <a href="mailto:info@pensionflow.de" className="text-blue-500">info@pensionflow.de</a></p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Verantwortlicher nach §§ 55 II RStV</h2>
                <p>Anabel van Heusden</p>
                <p>Taunustor 3</p>
                <p>60310 Frankfurt am Main</p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Berufsrechtliche Regelungen</h2>
                <p>§ 34d Gewerbeordnung (GewO)</p>
                <p>§§ 59-68 Gesetz über den Versicherungsvertrag (VVG)</p>
                <p>Verordnung über die Versicherungsvermittlung und -beratung (VersVermV)</p>
                <p>
                Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage{" "}
                <a href="http://www.gesetze-im-internet.de" className="text-blue-500" target="_blank" rel="noopener noreferrer">http://www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.
                </p>
                <br />
                <h2 className="text-2xl font-semibold mt-4" style={{ overflowWrap: 'break-word' }}>Versicherungsvermittlerregister</h2>
                <p>Register-Nr. D-9RO1-SC3SI-59 (für § 34d Abs. 1) Pensionflow GmbH</p>
                <p>
                Vermittlerregister{" "}
                <a href="http://www.vermittlerregister.info" className="text-blue-500" target="_blank" rel="noopener noreferrer">http://www.vermittlerregister.info</a>
                </p>
                <br />
                <p>Erlaubnis nach § 34d Abs. 1 GewO</p>
                <p>Aufsichtsbehörde: IHK Frankfurt am Main Börsenplatz 4 60313 Frankfurt am Main</p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Rechtliche Hinweise</h2>
                <p>
                Alle auf unseren Internetseiten veröffentlichten Angaben und Informationen wurden gewissenhaft recherchiert und geprüft. Trotz aller Sorgfalt können wir für die Richtigkeit, Vollständigkeit und Aktualität der zur Verfügung gestellten Informationen keine Garantie oder Haftung übernehmen, da sich die Daten mittlerweile geändert haben können.
                </p>
                <p>
                Alle Inhalte und Gestaltungen der Internetseiten von pensionflow sind urheberrechtlich geschützt. Eine Vervielfältigung dieser Seiten oder Inhalte bedarf einer vorherigen schriftlichen Zustimmung von pensionflow.
                </p>
            </div>
          </div>
      </section>
      <Footer />
    </div>
  );
}

export default Impressum;
