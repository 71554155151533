import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import Datenschutz from "./pages/Datenschutz";
import Erstinformation from "./pages/Erstinformation";
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import StrategyInfo from "./pages/StrategyInfo";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

function App() {
  

  const loadGoogleAnalytics = (trackingId: string) => {
    window.dataLayer = window.dataLayer || [];

    window.gtag = function (...args: any[]) {
      window.dataLayer.push(args);
    };

    window.gtag('js', new Date());
    window.gtag('config', trackingId);

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
          autoClear: {
              cookies: [
                  {
                      name: /^_ga/,   // regex: match all cookies starting with '_ga'
                  },
                  {
                      name: 'cc_cookie',   // string: exact cookie name
                  }
              ]
          },

          // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
          services: {
              ga: {
                  label: 'Google Analytics',
                  onAccept: () => {
                    const googleAnalyticsId = 'G-2D5DL7740S';
                    loadGoogleAnalytics(googleAnalyticsId);
                  },
                  onReject: () => {
                    console.log('Google Analytics was rejected');
                  },
              },
          }
        },
      },

      language: {
          default: 'de',
          translations: {
              de: {
                  consentModal: {
                      title: 'Wir verwenden Cookies',
                      description: '',
                      acceptAllBtn: 'Alle akzeptieren',
                      acceptNecessaryBtn: 'Alle ablehnen',
                      showPreferencesBtn: 'Konfigurieren',
                  },
                  preferencesModal: {
                      title: 'Datenschutzeinstellungen',
                      acceptAllBtn: 'Alle akzeptieren',
                      acceptNecessaryBtn: 'Alle ablehnen',
                      savePreferencesBtn: 'Auswahl speichern',
                      closeIconLabel: 'Close modal',
                      sections: [
                          {
                              title: 'Lege genau fest, welche Analysen wir durchführen dürfen!',
                              description: ''
                          },
                          {
                              title: 'Essentielle Cookies',
                              description: 'Diese Cookies sind für das einwandfreie Funktionieren der Website unerlässlich und können nicht deaktiviert werden.',

                              //this field will generate a toggle linked to the 'necessary' category
                              linkedCategory: 'necessary'
                          },
                          {
                              title: 'Performance und Analytics',
                              description: 'Diese Cookies sammeln Informationen darüber, wie du unsere Website nutzt. Alle Daten werden anonymisiert und können nicht dazu verwendet werden, dich zu identifizieren.',
                              linkedCategory: 'analytics'
                          },
                          {
                              title: 'Mehr Informationen',
                              description: 'Bei Fragen zum Thema Datenschutz oder zu den Auswahlmöglichkeiten kannst du uns gerne kontaktieren'
                          }
                      ]
                  }
              }
          }
      },

    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/impressum" element={<Impressum />}/>
        <Route path="/datenschutz" element={<Datenschutz />}/>
        <Route path="/erstinformation" element={<Erstinformation />}/>
        <Route path="/anlagestrategien" element={<StrategyInfo/>}/>
      </Routes>
    </Router>
  );
}

export default App;
