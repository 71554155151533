import Footer from "../components/footer";
import Navbar from "../components/navbar";

function Erstinformation() {
  return (
    <div>
      <Navbar isOnHome={false} />
      <section className="bg-[#F3F1FF] min-h-screen flex justify-center">
        <div className="container">
            <div className="py-5 px-4">
                <h1 className="text-3xl font-bold mb-4">Erstinformation</h1>
                <p>gemäß §15 Versicherungsvermittlungsverordnung</p>
                <br />
                <p>Pensionflow GmbH</p>
                <p>Taunustor 3</p>
                <p>60310 Frankfurt am Main</p>
                <p>Registergericht: Amtsgericht Frankfurt am Main HRB 135514</p>
                <p>Geschäftsführerin: Anabel van Heusden</p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Erlaubnis</h2>
                <p>
                Die Pensionflow GmbH ist als Versicherungsvertreter mit Erlaubnis nach § 34d Abs. 1 bei der Industrie- und Handelskammer Frankfurt am Main Börsenplatz 4 60313 Frankfurt am Main gemeldet. Die Pensionflow GmbH ist unter der Nummer D-9RO1-SC3SI-59 in das Versicherungsvermittlerregister eingetragen.
                </p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Beratung und Vergütung</h2>
                <p>
                Die Pensionflow GmbH bietet hinsichtlich der vermittelten Versicherungsprodukte eine Beratung an. Für die Vermittlung erhält die Pensionflow GmbH eine Vergütung in Form einer Provision die in der Versicherungsprämie enthalten und nicht direkt vom Kunden zu zahlen ist.
                </p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Gemeinsame Stelle im Sinne des § 11a Abs. 1 der GewO</h2>
                <p>Deutscher Industrie- und Handelskammertag (DIHK) e.V.</p>
                <p>Breite Straße 29</p>
                <p>10178 Berlin</p>
                <p>Tel. 0180 600 585 0 (20 Cent/Anruf aus dem dt. Festnetz höchstens 60 Cent/Anruf aus Mobilfunknetzen)</p>
                <p><a href="http://www.vermittlerregister.info" className="text-blue-500" target="_blank" rel="noopener noreferrer">www.vermittlerregister.info</a></p>
                <br />
                <h2 className="text-2xl font-semibold mt-4">Schlichtungsstellen</h2>
                <p>
                Bei Streitigkeiten zwischen Versicherungsvermittlern und Versicherungsnehmern kann folgende Schlichtungsstelle angerufen werden:
                </p>
                <p>Versicherungsombudsmann e.V.</p>
                <p>Postfach 08 06 32</p>
                <p>10006 Berlin</p>
                <br />
                <p>
                  Weitere anerkannte Schlichtungsstellen können der vom Bundesamt der Justiz geführten Liste der 
                  Verbraucherschlichtungsstellen entnommen werden welche unter folgendem Link aufgerufen werden kann:
                  <a href="https://www.bundesjustizamt.de/DE/Themen/Buergerdienste/Verbraucherstreitbeilegung/Verbraucherschlichtungsstellen/Uebersicht_node.html" className="text-blue-500" target="_blank" rel="noopener noreferrer">
                    {" "}Bundesjustizamt Website.
                  </a>
                </p>
            </div>
        </div>
      </section>
      
      <Footer />
    </div>
  )
}

export default Erstinformation;
