import React from 'react';
import YouTube from 'react-youtube';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";
import CloseIcon from '../assets/cross.png';

interface GetAppPopUpProps {
    isOpen: boolean;
    closeModal: () => void;
}

const VideoPopUp: React.FC<GetAppPopUpProps> = ({ isOpen, closeModal }) => {

    const opts = {
        width: '100%',
        height: "500",
        playerVars: {
            autoplay: 0,
        }
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel className="w-full lg:w-3/5 lg:h-max transform rounded-2xl bg-white p-6 flex flex-col shadow-xl transition-all gap-5">
                                <img onClick={closeModal} src={CloseIcon} style={{ width: 20, height: 20, alignSelf: 'flex-end', opacity: 0.2, cursor: 'pointer' }} loading="lazy"/>

                                <DialogTitle className="text-3xl font-black text-left">
                                    In 100 Sekunden erklärt
                                </DialogTitle>

                                <YouTube
                                    videoId="d4ISoiU-OzQ"
                                    opts={opts}
                                />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default VideoPopUp