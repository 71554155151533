export const faqItems = [
  {
    category: "Allgemein",
    questions: [
      {
        question: "Was ist Pensionflow?",
        answer: (
          <div className="faq-container">
            <p className="fap-paragraph">
              Pensionflow widmet sich der digitalen Altersvorsorge und hat es sich
              zum Ziel gesetzt, jungen Menschen eine kostengünstige, sichere und
              leicht verständliche private Altersvorsorge am Kapitalmarkt zu
              ermöglichen. Da das Rentenniveau durch den demografischen Wandel
              sinkt, müssen weniger Beitragszahler für mehr Rentner aufkommen.
              Daher reicht die gesetzliche Rente oft nicht aus.
            </p>
            <p className="fap-paragraph">
              Der Staat fördert allerdings private Vorsorge mit Steuervorteilen.
              Mit Pensionflow kannst du diese nutzen, indem du eine
              ETF-Rentenversicherung abschließt und digital über die App
              verwaltest.
            </p>
            <p className="fap-paragraph">
              Mit Pensionflow investierst du monatlich in kostengünstige ETFs und
              wählst aus drei renditestarken Strategien. Dank des Zinseszinseffekts
              wächst dein Vermögen über die Zeit. Deinen Sparplan kannst du
              jederzeit flexibel anpassen.
            </p>
            <p className="fap-paragraph">
              Im Vergleich zu herkömmlichen ETF-Sparplänen ist Pensionflow
              speziell für die Altersvorsorge optimiert. Die Vorteile umfassen:
            </p>
            <ul>
              <li>
                <strong>Steuervorteile:</strong> Nach 12 Jahren Einzahlung und ab
                dem 62. Lebensjahr profitierst du von erheblichen Steuervorteilen
                und zahlst auf regelmäßige Auszahlungen kaum Steuern.
              </li>
              <li>
                <strong>Lebenslange Rente:</strong> Du wählst zwischen einer
                lebenslangen Rente oder einer Einmalauszahlung des Gesamtbetrags –
                deine Entscheidung.
              </li>
              <li>
                <strong>Rentenprognose:</strong> Behalte stets den Überblick über
                deine erwartete Rente und den aktuellen Stand deines Guthabens.
              </li>
              <li>
                <strong>Automatisiertes Risikomanagement:</strong> Unser
                automatisches Risikomanagement kontrolliert Risiken und bietet
                Chancen auf Zusatzrenditen. Zehn Jahre vor Renteneintritt wird
                schrittweise von Aktien in sichere Anleihen umgeschichtet, um
                Schwankungen vor der Auszahlungsphase abzufedern.
              </li>
              <li>
                <strong>Zusätzliche Leistungen im Pflegefall:</strong> Für die
                Pflege-Option zahlst du keinen zusätzlichen Beitrag und im
                Pflegefall verdoppelt sich deine Rente auf bis zu 48.000 € pro
                Jahr.
              </li>
              <li>
                <strong>Flexibilität:</strong> Beiträge werden per Lastschrift
                eingezogen. Du kannst jederzeit deinen Monatsbeitrag anpassen, Ein-
                und Auszahlungen flexibel vornehmen und den Sparplan kostenlos
                kündigen – so wie du es von herkömmlichen ETF-Sparplänen gewohnt
                bist.
              </li>
            </ul>
            <p className="fap-paragraph">
              Die ETF-Rentenversicherung von Pensionflow vereint die Vorteile
              eines ETF-Sparplans mit einer klassischen Rentenversicherung.
              Berechne deine Rentenlücke und erfahre, wie viel du zusätzlich
              sparen musst, um deinen Lebensstandard im Alter zu sichern.
            </p>
            <p className="fap-paragraph">
              Dein Vertragspartner und Risikoträger der Rentenversicherung ist die
              Alte Leipziger.
            </p>
          </div>
        ),
      },
      {
        question: "Wer kann Pensionflow nutzen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Pensionflow kann im Alter von 18 bis 50 Jahren abgeschlossen werden.
            Voraussetzung ist, dass du deinen steuerlichen Wohnsitz in
            Deutschland hast. US-amerikanische Staatsbürger sind leider von der
            Nutzung von Pensionflow ausgeschlossen.
          </p>
        ),
      },
      {
        question: "Warum sollte ich mich um eine private Altersvorsorge kümmern?",
        answer: (
          <div className="faq-container">
            <p>
              Eine private Altersvorsorge, beispielsweise in Form einer
              ETF-Rentenversicherung von Pensionflow, sollte jeder in Betracht
              ziehen, der seinen aktuellen Lebensstandard auch im Ruhestand
              beibehalten möchte.
            </p>
            <p>
              In Deutschland zahlen die meisten Arbeitnehmer während ihres
              Berufslebens in die gesetzliche Rentenversicherung ein. Beim
              Eintritt in den Ruhestand erhalten sie vom Staat eine Rente aus
              dieser Pflichtversicherung. Allerdings entspricht diese Rente
              weniger als die Hälfte des durchschnittlichen Einkommens.
            </p>
            <p>
              Statistisch gesehen werden die Menschen in Deutschland immer älter
              und beziehen daher länger Rente. Gleichzeitig gibt es durch den
              demografischen Wandel immer weniger junge Arbeitnehmer, die in die
              gesetzliche Rentenversicherung einzahlen. Dies führt zu einer
              Rentenlücke, also einer wachsenden Differenz zwischen dem letzten
              Einkommen und der tatsächlichen Rente.
            </p>
            <p>
              Durch eine gezielte private Vorsorge kannst du die Leistungen der
              gesetzlichen Rentenversicherung ergänzen und so deine individuelle
              Rentenlücke schließen.
            </p>
          </div>
        ),
      },
      {
        question: "Was ist die Rentenlücke?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Die Rentenlücke bezeichnet die Differenz zwischen der voraussichtlichen
            gesetzlichen Rente und dem tatsächlich benötigten Einkommen im
            Ruhestand, um den Lebensstandard aufrechtzuerhalten. Sie entsteht,
            wenn die gesetzliche Rente nicht ausreicht, um die Lebenshaltungskosten
            zu decken. Diese Lücke muss durch private Altersvorsorge oder andere
            Einkommensquellen geschlossen werden.
          </p>
        ),
      },
      {
        question: "Was ist eine ETF-Rentenversicherung?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Die ETF-Rentenversicherung ist eine private fondsgebundene
            Altersvorsorge, die in kostengünstige ETFs investiert. Als Produkt der
            dritten Schicht bietet sie maximale Flexibilität. Die Leistungen können
            am Vertragsende entweder als lebenslange Rente oder als einmalige
            Kapitalauszahlung bezogen werden. Zudem sind Kapitalentnahmen während
            der Laufzeit möglich. Ein besonderer Vorteil ist das steuerliche
            Halbeinkünfteverfahren: Nach 12 Jahren Laufzeit und ab dem 62.
            Lebensjahr sind nur 50% der Erträge steuerpflichtig. Bei einer
            lebenslangen Rente profitiert der Sparer von der günstigen
            Ertragsanteilbesteuerung.
          </p>
        ),
      },
      {
        question: "Was sind die Vorteile einer ETF-Rentenversicherung im Vergleich zu einem ETF-Sparplan?",
        answer: (
          <div className="faq-container">
            <p>
              Da du nicht weißt, wie lange du leben wirst, kannst du auch nicht
              genau abschätzen, wie viel Geld du im Ruhestand benötigen wirst. Wenn
              das Kapital aus deinem ETF-Sparplan aufgebraucht ist, bleibt dir nur
              die gesetzliche Grundrente.
            </p>
            <p>
              Die ETF-Rentenversicherung bietet jedoch die Möglichkeit, dir eine
              lebenslange monatliche Rente auszahlen zu lassen, unabhängig davon,
              wie alt du wirst. Darüber hinaus profitierst du von erheblichen
              Steuervorteilen während der Vertragslaufzeit und bei der Auszahlung
              (nach der 12/62-Regel).
            </p>
            <p>
              Zusätzlich bietet die ETF-Rentenversicherung nützliche Funktionen
              wie eine Pflege-Option, Rentenprognose und automatisches
              Risikomanagement. Wie bei einem herkömmlichen ETF-Sparplan kannst du
              deine monatlichen Sparbeträge anpassen, dein vollständiges Guthaben
              jederzeit auszahlen lassen und den Sparplan pausieren.
            </p>
          </div>
        ),
      },
      {
        question: "Welche Leistungen sind im Rentenalter vorgesehen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Du kannst zwischen einer lebenslangen Altersrente oder einer einmaligen
            Kapitalauszahlung wählen. Eine garantierte monatliche Altersrente gibt
            es nicht; die Höhe hängt von der Wertentwicklung der ETFs ab.
          </p>
        ),
      },
      {
        question: "Was bedeutet die enthaltene Pflegeoption für mich?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Für die Pflege-Option zahlst du keinen zusätzlichen Beitrag. Du kannst
            sie jedoch nur zu Beginn des Rentenbezugs ausüben. Die garantierte
            Pflegerente entspricht der Höhe der garantierten Altersrente, sodass
            sich die garantierte Leistung im Pflegefall verdoppelt. Die garantierte
            Pflegerente darf dabei maximal 48.000€ pro Jahr betragen.
          </p>
        ),
      },
      {
        question: "Wieso muss ich mich identifizieren?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Pensionflow ist gesetzlich verpflichtet, deine Identität zu
            überprüfen. Dazu kontrollieren wir deinen Ausweis und vergleichen ihn
            mit den Informationen, die du uns bei der Registrierung mitgeteilt
            hast. Die Online-Identifizierung erfolgt direkt in der App über
            unseren Partner WebID Solutions.
          </p>
        ),
      },
      {
        question: "Wie kann ich die Pensionflow App herunterladen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Du kannst die Pensionflow App im App Store oder Google Play Store
            herunterladen.
          </p>
        ),
      },
    ],
  },
  {
    category: "Sparplan",
    questions: [
      {
        question: "Welche Anlagestrategie soll ich wählen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Pensionflow hat drei renditestarke Anlagestrategien für dich
            entwickelt, sodass du nicht mühsam deine ETFs selbst auswählen musst.
            Alle Strategien investieren in ein diversifiziertes Weltportfolio,
            wobei du zwischen einer Aktienquote von 60%, 80% oder 100% wählen
            kannst, optional mit ESG-Kriterien. Für die Zusammenstellung des
            Portfolios nutzt Pensionflow kostengünstige ETFs von Amundi, State
            Street, Vanguard, iShares und UBS. Weitere Infos findest du in der
            App.
          </p>
        ),
      },
      {
        question: "Wie viel Geld sollte ich anlegen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Als Faustregel gilt, dass du im Ruhestand etwa 80% deines letzten
            Einkommens benötigst, um deinen Lebensstandard aufrechterhalten zu
            können. Nach Abzug deines gesetzlichen Rentenanspruchs entsteht die
            sogenannte Rentenlücke, die es zu schließen gilt. Eine private
            ETF-Altersvorsorge wie Pensionflow kann dazu einen großen Beitrag
            leisten. Nutze den Rentenrechner auf www.pensionflow.de, um einen
            Richtwert zu berechnen.
          </p>
        ),
      },
      {
        question: "Wie viel Geld muss ich mindestens monatlich anlegen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Der monatliche Sparbetrag muss mindestens 30 € betragen, zusätzliche
            Einzahlungen sind optional.
          </p>
        ),
      },
      {
        question: "Kann man die monatlichen Sparbeiträge erhöhen oder senken?",
        answer: (
          <div className="faq-container">
            <p>
              Du kannst deinen Beitrag jederzeit per App im Pensionflow Dashboard für die Zukunft erhöhen oder senken. Dazu musst du Folgendes beachten:
            </p>
            <ul>
              <li>Der neue Beitrag muss mindestens 30€ im Jahr betragen.</li>
              <li>Der neue Beitrag darf 3.000€ im Monat nicht übersteigen.</li>
              <li>Zuzahlungen und Beiträge dürfen insgesamt 40.000€ pro Jahr nicht überschreiten.</li>
            </ul>
          </div>
        ),
      },
      {
        question: "Kann ich meine Beiträge pausieren?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Du kannst die Beiträge jederzeit stoppen. Sollte dein Guthaben für
            einen unbefristeten Beitragsstopp nicht ausreichen, wird der Vertrag
            automatisch beendet und wir zahlen dir den Rückkaufswert aus. Für
            einen Beitragsstopp muss immer ein ausreichendes Guthaben vorhanden
            sein. Wir weisen dich in der App darauf hin, falls dein Guthaben nicht
            für den Beitragsstopp ausreicht.
          </p>
        ),
      },
      {
        question: "Kann ich die Parameter wie den monatlichen Sparbeitrag und die Strategie nachträglich ändern?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Du kannst alle Parameter aus dem Registrierungsprozess direkt in der
            App anpassen. Weitere Einstellungen, wie die Rentengarantiezeit oder
            Beitragsdynamiken, kannst du nur per E-Mail an
            support@pensionflow.de ändern. Schreibe uns einfach eine E-Mail mit
            deinem Änderungswunsch.
          </p>
        ),
      },
      {
        question: "Was bedeutet Rebalancing?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Beim Rebalancing wird einmal pro Jahr die prozentuale Aufteilung der
            ETFs wiederhergestellt. Dies hilft, Risiken zu kontrollieren und
            bietet Chancen auf zusätzliche Renditen.
          </p>
        ),
      },
      {
        question: "Wie funktioniert das Ablaufmanagement?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Das Ziel des Ablaufmanagements ist es, kurzfristige Kursschwankungen zu minimieren und 
            hohe Verluste kurz vor der Auszahlungsphase zu verhindern. Mit dieser Option wird fünf 
            Jahre vor dem Ruhestand schrittweise und automatisiert von Aktien in sichere Anleihen umgeschichtet. 
            Diese Umschichtungen sind kostenfrei und abgeltungsteuerfrei. Wenn du dies nicht möchtest, kannst 
            du das Ablaufmanagement im PensionFlow-Dashboard deaktivieren.
          </p>
        ),
      },
    ],
  },
  {
    category: "Ein- und Auszahlung",
    questions: [
      {
        question: "Wie kann ich zusätzliche Einzahlungen vornehmen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Du kannst jederzeit zusätzliche Einzahlungen über das Pensionflow
            Dashboard vornehmen. Diese außerordentlichen Einzahlungen müssen
            mindestens 500 € betragen und erfolgen automatisch per Lastschrift.
            Abhängig von der Höhe des Betrags und den Prozessen deiner Bank kann
            es 5 bis 10 Bankarbeitstage dauern, bis die Einzahlung im Pensionflow
            Dashboard sichtbar ist.
          </p>
        ),
      },
      {
        question: "Wie kann ich Auszahlungen tätigen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Du kannst jederzeit Auszahlungen über das Pensionflow Dashboard
            vornehmen. Abhängig von der Höhe des Betrags und den Prozessen deiner
            Bank kann es 5 bis 10 Bankarbeitstage dauern, bis das Geld auf deinem
            Konto ist. Für Auszahlungen während der Ansparphase wird auf deine
            Erträge Abgeltungssteuer fällig, die automatisch abgeführt wird.
          </p>
        ),
      },
      {
        question: "Sind Auszahlungen auch in Krisenzeiten möglich?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Ja, Auszahlungen sind auch in Krisenzeiten problemlos möglich. Oft ist es jedoch die 
            bessere Strategie, ruhig zu bleiben und mit Geduld und Disziplin investiert zu bleiben. 
            Zudem greift der Cost-Average-Effekt: Da du bei niedrigen Kursen mehr Anteile erwirbst, 
            profitierst du nach Überwindung der Schwächephase umso mehr von den Wertsteigerungen.
          </p>
        ),
      },
    ],
  },
  {
    category: "Steuer",
    questions: [
      {
        question: "Wie wird Pensionflow in der Ansparphase besteuert?",
        answer: (
          <div className="faq-container">
            <p style={{ margin: "10px 0" }}>
              Solange dein Guthaben bei Pensionflow investiert ist, bleiben alle Erträge – ob Dividenden, 
              Zinsen oder Kursgewinne durch jährliches Rebalancing oder eine Änderung der 
              Anlagestrategie – steuerfrei. Die Versteuerung erfolgt erst bei der Auszahlung.
            </p>
            <p>
              Im Gegensatz dazu müssen bei einem herkömmlichen ETF-Sparplan die realisierten Erträge, 
              wie Dividenden oder Kursgewinne beim Rebalancing, jährlich versteuert werden.
            </p>
            <p>
              Mit Pensionflow kannst du somit die Vorteile von Zins und Zinseszins voll ausschöpfen.
            </p>
          </div>
        ),
      },
      {
        question: "Wie wird Pensionflow in der Auszahlungsphase besteuert?",
        answer: (
          <div className="faq-container">
            <p style={{ margin: "10px 0" }}>
              Bei einer ETF-Rentenversicherung gilt die 12/62-Regel. Wenn du
              mindestens 12 Jahre lang einzahlst und das 62. Lebensjahr erreicht
              hast, profitierst du von erheblichen Steuervorteilen im Vergleich zu
              einem herkömmlichen ETF-Sparplan. Weitere Informationen findest du
              unter „12/62-Regel“.
            </p>
            <p>
              Solltest du die 12/62-Regel nicht einhalten können und eine vorzeitige
              Auszahlung vornehmen, werden die üblichen Steuern fällig: die
              Abgeltungssteuer (25%), der Solidaritätszuschlag (1,375%) und
              gegebenenfalls die Kirchensteuer.
            </p>
          </div>
        ),
      },
      {
        question: "Was ist die 12/62 Regel?",
        answer: (
          <div className="faq-container">
            <p>
              Bei einer ETF-Rentenversicherung kommt die 12/62-Regel zur Anwendung.
              Das bedeutet, dass du von erheblichen Steuervorteilen gegenüber
              einem herkömmlichen ETF-Sparplan profitierst, wenn du mindestens 12
              Jahre lang einzahlst und 62 Jahre alt bist.
            </p>
            <p style={{ textDecoration: 'underline' }}>Einmalige Auszahlung:</p>
            <p>
              Hier greift das Halbeinkünfteverfahren. Die Hälfte des Gewinns
              bleibt steuerfrei, während die andere Hälfte mit dem persönlichen
              Steuersatz versteuert wird. Zudem gilt vorab eine Teilfreistellung
              von 15%. Das bedeutet, dass 15% des Gewinns komplett steuerfrei
              sind. Betrachten wir ein Beispiel mit einem Gewinn von 100.000 € und einem persönlichen Grenzsteuersatz von 30%:
            </p>
            <ul>
              <li>100.000 € Gewinn x 0,85 (nach Teilfreistellung) = 85.000 €</li>
              <li>85.000 € x 0,5 (die Hälfte ist steuerfrei) = 42.500 €</li>
              <li>42.500 € x 0,3 (beispielhafter Steuersatz) = 12.750 € Steuer</li>
            </ul>
            <p style={{ textDecoration: 'underline' }}>Monatliche Rente:</p>
            <p>
              Hier wird das Ertragswertverfahren angewendet. Der zu versteuernde
              Anteil der Rente (Ertragsanteil) hängt vom Alter bei Rentenbeginn
              ab. Wenn du zum Beispiel mit 67 Jahren die Rente erstmals in
              Anspruch nimmst, sind nur 17% der Rente zu versteuern. Bei einem
              persönlichen Steuersatz von 30% und einer monatlichen Rente von 1000
              € ergibt sich folgende Berechnung:
            </p>
            <ul>
              <li>1000 € x 0,17 (Ertragsanteil) = 170 €</li>
              <li>170 € x 0,3 (persönlicher Steuersatz) = 51 € Steuer</li>
            </ul>
          </div>
        ),
      },
      {
        question: "Können die Beiträge steuerlich geltend gemacht werden?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Da Pensionflow eine private Altersvorsorge der 3. Schicht ist, kannst
            du deine Beiträge nicht wie bei der Rürup-Rente in der jährlichen
            Steuererklärung absetzen. Stattdessen werden jedoch die Kapital- oder
            Rentenzahlungen mit erheblichen steuerlichen Vergünstigungen
            gefördert.
          </p>
        ),
      },
    ],
  },
  {
    category: "Vertrag",
    questions: [
      {
        question: "Wer führt das Depot bei Pensionflow?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Es handelt sich bei Pensionflow um eine Versicherung und kein
            Wertpapierdepot. Deswegen gibt es kein separates Depot.
          </p>
        ),
      },
      {
        question: "Wie ermitteln wir den Wert deines Guthabens?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Dein Guthaben wird berechnet, indem die Anzahl der ETF-Anteile mit
            dem aktuellen Kurs des jeweiligen ETFs multipliziert wird. Für ETFs
            in Fremdwährungen wird der Wert zum jeweils aktuellen Wechselkurs in
            Euro umgerechnet. Wenn ETFs Erträge ausschütten, werden diese Erträge
            genutzt, um weitere Anteile desselben ETFs zu erwerben und schreiben
            diese deinem Guthaben gut. Bei nicht ausschüttenden ETFs fließen die
            Erträge direkt in die ETFs und erhöhen den Wert der Anteile.
          </p>
        ),
      },
      {
        question: "Was ist der Rentenfaktor?",
        answer: (
          <div className="faq-container">
            <p>
              Da es sich bei Pensionflow um eine ETF-basierte Rentenversicherung handelt, 
              wird in deinem Vertrag ein Rentenfaktor festgelegt. Dieser gibt an, wie viel 
              monatliche Rente du pro 10.000 € angespartem Guthaben erhältst. Dabei wird 
              zwischen zwei Rentenfaktoren unterschieden: dem aktuellen und dem garantierten 
              Rentenfaktor.
            </p>
            <p style={{ textDecoration: 'underline' }}>Aktueller Rentenfaktor:</p>
            <ul>
              <li>
                Der aktuelle Rentenfaktor bietet eine Orientierung zur Höhe deiner späteren Rente, 
                vorausgesetzt, die zugrunde liegenden Annahmen, wie etwa Lebenserwartung und Zinsen, bleiben unverändert.
              </li>
            </ul>
            <p style={{ textDecoration: 'underline' }}>Garantierter Rentenfaktor:</p>

            <ul>
              <li>
                Der garantierte Rentenfaktor stellt die Mindestrente dar und kommt zum Einsatz, wenn der aktuelle 
                Rentenfaktor zum Rentenbeginn niedriger ausfallen sollte als der garantierte Rentenfaktor.
              </li>
              <li>
                Du kannst am Ende selbst entscheiden, ob du eine monatliche Rente erhalten möchtest 
                oder einfach das vollständige Guthaben ausgezahlt haben willst.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: "Warum erhalte ich Post von der Alte Leipziger?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Pensionflow agiert als Versicherungsvermittler und stellt die
            digitale Plattform bereit. Dein Vertragspartner ist jedoch die Alte
            Leipziger, die dir gemäß Vertrag alle Mitteilungen in Textform zur
            Verfügung stellen muss.
          </p>
        ),
      },
      {
        question: "Wie kann ich Pensionflow kündigen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Direkt in der App unter Einstellungen. Der Auszahlungsbetrag
            entspricht deinem Guthaben abzüglich eventuell anfallender Steuern.
            Beachte, dass der Auszahlungsbetrag von dem im Pensionflow Dashboard
            zuletzt angezeigten Wert abweichen kann. Dies kann beispielsweise
            durch anfallende Steuern oder durch Änderungen des ETF-Wertes bis zum
            technischen Bearbeitungszeitpunkt der Kündigung verursacht werden. Es
            fallen keine Kosten an.
          </p>
        ),
      },
      {
        question: "Wie kann ich Änderungen an meinem Vertrag vornehmen, wenn die Pensionflow App vorübergehend nicht erreichbar ist?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Sollte die Pensionflow App vorübergehend nicht erreichbar sein,
            kannst du Änderungen an deinem Vertrag, wie beispielsweise Anpassungen
            des Sparbeitrags, Ein- und Auszahlungen oder andere Anliegen,
            weiterhin per E-Mail an support@pensionflow.de oder über WhatsApp an
            uns senden. Wir kümmern uns schnellstmöglich darum.
          </p>
        ),
      },
      {
        question: "Was geschieht mit meinem Vertrag im Todesfall?",
        answer: (
          <div className="faq-container">
            <p style={{ textDecoration: 'underline' }}>Vor Rentenbeginn:</p>
            <ul>
              <li>
                Solltest du vor Rentenbeginn sterben, erhalten deine Hinterbliebenen
                den Wert des angesparten Guthabens ausgezahlt. Dabei können
                Erbschaftssteuern anfallen.
              </li>
            </ul>
            <p style={{ textDecoration: 'underline' }}>Nach Rentenbeginn:</p>
            <ul>
              <li>
                Standardmäßig ist eine Rentengarantiezeit von 10 Jahren festgelegt.
                Das bedeutet, dass deine Hinterbliebenen die Rentenzahlungen bis
                zehn Jahre nach deinem Renteneintritt weiter erhalten. Diese
                Einstellung kann nur per Mail und nicht über die App geändert
                werden.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    category: "Kosten",
    questions: [
      {
        question: "Welche Kosten entstehen für mich?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Für den Abschluss der ETF-Rentenversicherung fallen keine Kosten an.
            Es entstehen monatlich 3 € Verwaltungskosten, die direkt vom
            monatlichen Sparbetrag abgezogen werden. Weitere guthabenbezogene
            Kosten betragen 0,1% pro Monat, wobei die ETF-Kosten bereits
            enthalten sind. Die jährliche Effektivkostenquote liegt daher bei ca.
            1,3%, abhängig von der gewählten Anlagestrategie.
          </p>
        ),
      },
      {
        question: "Gibt es zusätzliche Kosten bei Kündigung oder Auszahlungen?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Kündigungen, Auszahlungen und Zuzahlungen sind kostenfrei. Bitte
            beachte jedoch, dass der Auszahlungsbetrag vom zuletzt im Pensionflow
            Dashboard angezeigten Guthaben abweichen kann. Dies kann durch
            anfallende Steuern oder Änderungen des ETF-Werts bis zum technischen
            Bearbeitungszeitpunkt der Kündigung verursacht werden.
          </p>
        ),
      },
    ],
  },
  {
    category: "Sicherheit",
    questions: [
      {
        question: "Was geschieht im Falle einer Insolvenz von Pensionflow?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Aus rechtlicher Sicht ist Pensionflow ein Versicherungsvermittler. Dein Guthaben wird deshalb von unserem 
            Versicherungspartner Alte Leipziger angelegt. Die Alte Leipziger Lebensversicherung a.G. hat wiederholt 
            die Note „A+“ mit stabilem Ausblick von der renommierten Ratingagentur Fitch erhalten. Sollte Pensionflow 
            einmal nicht mehr existieren, läuft dein Vertrag ganz normal weiter bei der Alte Leipziger.
          </p>
        ),
      },
      {
        question: "Was geschieht im Falle einer Insolvenz von der Alte Leipziger?",
        answer: (
          <div className="faq-container">
            <p>
              Falls unser Versicherungspartner Alte Leipziger in finanzielle
              Probleme gerät, bleibt dein Guthaben im Sicherungsvermögen und wird
              nicht der Insolvenzmasse zugeführt. Dadurch hast du in diesem Fall
              Anspruch auf die vollständige Auszahlung deines Guthabens.
            </p>
            <p>
              Unser Partner ist zudem, wie alle anderen Versicherer, durch die
              Sicherungseinrichtung der deutschen Lebensversicherer, die Protektor
              AG, abgesichert.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    category: "Support",
    questions: [
      {
        question: "Wen kann ich bei Fragen oder Problemen kontaktieren?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Bei technischen Fragen oder Fragen zum Vertrag wende dich gerne an
            den Support per WhatsApp in der App oder per Email an
            support@pensionflow.de.
          </p>
        ),
      },
      {
        question: "Wie kann ich Feedback zu Pensionflow geben?",
        answer: (
          <p style={{ margin: "10px 0" }}>
            Wir freuen uns über eure Anregungen und euer Feedback! Schreibt uns
            gerne an feedback@pensionflow.de, wie wir Pensionflow weiter
            verbessern können, um die Altersvorsorge für junge Menschen noch
            einfacher und verständlicher zu gestalten. Denn ihr alle habt ein
            sorgenfreies Leben im Alter verdient.
          </p>
        ),
      },
    ],
  },
];
