import Footer from "../components/footer";
import Navbar from "../components/navbar";
function Datenschutz() {
    return (
        <div>
            <Navbar isOnHome={false} />
            <section className="bg-[#F3F1FF] min-h-screen flex justify-center">
                <div className="container">
                    <div className="py-5 px-4">
                        <h1 className="text-3xl font-bold mb-4" style={{ overflowWrap: 'break-word' }}>Datenschutzerklärung</h1>
                        
                        <p>Diese Datenschutzerklärung gilt für die Datenverarbeitung der Pensionflow GmbH („<strong>Pensionflow</strong>“/“<strong>wir</strong>“/“<strong>uns</strong>“/“<strong>unser</strong>“). Unsere Kontaktdaten lauten Pensionflow GmbH, Taunustor 3, 60310 Frankfurt am Main, Deutschland, <a href="mailto:info@pensionflow.de" className="text-blue-500">info@pensionflow.de</a>.</p>
                        <p>Unsere Dienstleistungen werden in unseren Nutzungsbedingungen konkretisiert. Die Verarbeitung von personenbezogenen Daten im Rahmen unserer Dienstleistungen sowie der Bereitstellung unserer Internetseite (<a href="https://www.pensionflow.de" className="text-blue-500">https://www.pensionflow.de</a>) und unserer App (zusammenfassend „<strong>Dienste</strong>“) erfolgt ausschließlich gemäß der nachfolgenden Datenschutzerklärung.</p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">I. Wie wir Technische Daten erheben und verarbeiten</h2>
                        <p>
                            Wenn Nutzer auf unsere Dienste zugreifen, erheben und verarbeiten wir kurzzeitig technische Informationen („<strong>Technische Daten</strong>“). 
                            Zu den Technischen Daten gehören insbesondere die IP-Adresse und der Name des Access-Providers, die Adresse der Webseite, die 
                            vor Nutzung unserer Internetseite besucht wurde, das Betriebssystem, der Browsertyp und die Browsereinstellungen, Informationen 
                            über die Browserkonfiguration und Plugins sowie Spracheinstellungen. Diese Daten werden von uns verarbeitet, um einen reibungslosen 
                            Verbindungsaufbau und eine komfortable Nutzung sowie die Sicherheit und Stabilität unserer Dienste zu gewährleisten, ebenso wie für 
                            andere administrative Zwecke.
                        </p>
                        <p>
                            Die Rechtsgrundlage für die Verarbeitung der Technischen Daten ist unser berechtigtes Interesse, einen komfortablen und 
                            benutzerfreundlichen Online-Dienst anzubieten. Die erhobenen Daten werden in keinem Fall dazu verwendet, einen Rückschluss 
                            auf die Identität unserer Nutzer zu ziehen. Die Daten werden gelöscht, wenn sie für die genannten Zwecke nicht mehr benötigt 
                            werden und keine anderweitige Rechtsgrundlage für die Verarbeitung der Daten besteht.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">II. Wie wir Nutzungsdaten erfassen und verarbeiten</h2>
                        <p>
                            Bei Nutzung unserer Dienste erheben wir Nutzungsdaten, z.B. welche Inhalte sich Nutzer ansehen oder wie sie anderweitig mit unseren 
                            Diensten interagieren („<strong>Nutzungsdaten</strong>“). Zu den Nutzungsdaten gehören insbesondere die Art, der Zeitpunkt und der Umfang der 
                            Interaktionen mit unseren Diensten, technische Aspekte der genutzten Endgeräte und Browser sowie über welche Quellen die Nutzer 
                            auf unsere Internetseite gekommen sind. Es werden hierbei teilweise auch geräte- und sessionübergreifende pseudonyme Profile von 
                            Nutzern erstellt und verarbeitet. Wir speichern in diesem Zusammenhang keine individuellen IP-Adressen dauerhaft; diese können allerdings 
                            kurzzeitig verarbeitet werden, insbesondere um daraus geografische Metadaten abzuleiten und zu erfassen (z.B. Kontinent, Land, Region).
                        </p>
                        <p>
                            Um besser zu verstehen, wie unsere Dienste genutzt werden und um unser Angebot besser auf die Bedürfnisse und Wünsche 
                            unserer Nutzer abstimmen zu können, werten wir pseudonymisierte Daten über die Nutzung unserer Dienste aus. Die 
                            Analyse ermöglicht es uns, allgemeine Nutzungsgewohnheiten zu verstehen sowie verschiedene Ziel- und Nutzergruppen zu 
                            erstellen. Wir verwenden diese Informationen, um das Nutzererlebnis unserer Dienste und unsere Dienstleistungen zu verbessern
                        </p>
                        <p>
                            Je nach Art und Umfang der Erhebung und Verarbeitung der Nutzungsdaten stützen wir uns entweder auf die Einwilligung der 
                            Nutzer oder auf unser berechtigtes Interesse an der statistischen Auswertung und der Verbesserung der Funktionalität unserer Dienste. 
                            Eine abgegebene Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden. Die Daten werden gelöscht, wenn sie für die 
                            genannten Zwecke nicht mehr benötigt werden und keine anderweitige Rechtsgrundlage für die Verarbeitung der Daten besteht. 
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">III. Wie wir Vertragsdaten sammeln und verarbeiten</h2>
                        <p>
                            Im Rahmen der Erbringung unserer Dienstleistungen müssen bestimmte personenbezogene Daten der Nutzer wie Anmeldedaten, Kontaktinformationen sowie Zahlungsinformationen erfasst und verarbeitet (zusammengefasst bezeichnet als „<strong>Vertragsdaten</strong>“) werden. Zu den Vertragsdaten gehören insbesondere Vor- und Nachname, Geburtsdatum, Geburtsort, Geschlecht, Staatsangehörigkeit, Adresse (z.B. Straße, Ort, Postleitzahl), Steueransässigkeit, Telefonnummer, E-Mail-Adresse und Kontodaten (z.B. IBAN) ebenso wie Benutzernamen und Passwörter.
                        </p>
                        <p>
                            Wir verarbeiten diese Daten zum einen, um unsere eigenen Dienstleistungen erbringen zu können, zum Beispiel für die eigene Kunden- und Vertragsverwaltung. Zum anderen werden diese Daten verarbeitet, um sie dem Versicherer zur Verfügung stellen zu können. Sobald wir die Daten dem Versicherer übermittelt haben, verarbeitet er diese in eigener Verantwortung. Unsere Datenverarbeitung erfolgt somit auf Grundlage der Durchführung von vorvertraglichen Maßnahmen und zur Erfüllung sowohl unseres Vertrags mit den Nutzern als auch des Vertrags zwischen den Nutzern und den Versicherern, als deren Vermittler wir agieren.
                        </p>
                        <p>
                        Die Vertragsdaten werden gelöscht, wenn sie für die genannten Zwecke nicht mehr benötigt werden und keine anderweitige Rechtsgrundlage für die Verarbeitung der Daten besteht. Bei den in Anbahnung und zur Erfüllung unseres eigenen Vertrags erhobenen Vertragsdaten ist dies in der Regel der Fall, wenn der Nutzer sein Benutzerkonto oder einzelne Daten löscht oder aktualisiert. Bei Vertragsdaten, die wir lediglich zur Durchführung von vorvertraglichen Maßnahmen erhoben haben, erfolgt eine Löschung in der Regel, nachdem der Vertragsschluss endgültig erfolgt oder nicht zustande gekommen ist. Auch nach Löschung des Benutzerkontos kann es erforderlich sein, personenbezogene Daten so lange zu speichern, wie es zur Erfüllung vertraglicher oder gesetzlicher Pflichten erforderlich ist. Die entsprechenden Daten werden dann gelöscht, sobald die vertragliche oder gesetzliche Verpflichtung nicht mehr besteht.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">IV. Wie wir Beratungsdaten sammeln und verarbeiten</h2>
                        <p>
                        Im Rahmen der Erbringung unserer Dienstleistungen erbringen wir auch Beratungsleistungen gegenüber unseren Nutzern. Hierbei verarbeiten wir insbesondere auch Daten zu den persönlichen, finanziellen oder beruflichen Umständen der Nutzer, soweit diese Daten für das jeweilige Versicherungsverhältnis relevant sind („<strong>Beratungsdaten</strong>“). Zu den Beratungsdaten können grundsätzlich auch Gesundheitsdaten und Daten zur religiösen Überzeugung im Sinne von Art. 9 Abs. 1 DSGVO gehören.
                        </p>
                        <p>
                            Wir verarbeiten diese Daten, um als Versicherungsvermittler unsere eigenen Dienstleistungen erbringen zu können, unserer gesetzlichen Beratungspflicht nach § 61 VVG nachzukommen, sowie um sie dem Versicherer zur Verfügung stellen zu können. Unsere Datenverarbeitung erfolgt somit auf Grundlage der Durchführung von vorvertraglichen Maßnahmen und zur Erfüllung sowohl unseres Vertrags mit den Nutzern als auch des Vertrags zwischen den Nutzern und den Versicherern, als deren Vermittler wir agieren, sowie zur Erfüllung einer rechtlichen Verpflichtung, der wir als Verantwortliche unterliegen. Soweit wir von unseren Nutzern Daten im Sinne von Art. 9 Abs. 1 DSGVO verarbeiten, erfolgt dies auf Grundlage der ausdrücklichen Einwilligung unserer Nutzer.
                        </p>
                        <p>
                            Die Beratungsdaten werden gelöscht, wenn sie für die genannten Zwecke nicht mehr benötigt werden und keine anderweitige Rechtsgrundlage für die Verarbeitung der Daten besteht.
                        </p>
                        <br />
                    

                        <h2 className="text-2xl font-semibold mt-4">V. Wie wir Daten sammeln und verarbeiten, wenn wir Marketingnachrichten (z.B. Newsletter) versenden</h2>
                        <p>
                            Sofern Nutzer dies wünschen, senden wir ihnen Informationen über unsere Dienstleistungen. Zu diesem Zweck erheben und verarbeiten wir insbesondere die E-Mail-Adresse, Telefonnummer sowie Vor- und Nachname der Nutzer. Dies erfolgt beispielsweise im Rahmen von Newslettern, zu denen sich Nutzer anmelden können
                        </p>
                        <p>
                            Wir nutzen hierbei ein Tool, um die Resonanz der Nutzer auf unsere Marketingnachrichten zu analysieren. So können wir z.B. sehen, ob Marketingnachrichten geöffnet wurden und welche Links ggf. angeklickt wurden. Auf diese Weise können wir unter anderem feststellen, welche Links besonders häufig angeklickt werden. Außerdem können wir sehen, ob nach dem Öffnen/Klicken bestimmte, vorher definierte Aktionen durchgeführt wurden (Conversion Rate). Dieses Tool ermöglicht es uns auch, die Empfänger unserer Marketingnachrichten nach verschiedenen Kategorien zu unterteilen. Auf diese Weise können die Marketingnachrichten besser an die jeweiligen Zielgruppen angepasst werden.
                        </p>
                        <p>
                        Die Verarbeitung der Daten erfolgt auf Grundlage der Einwilligung der Nutzer. Nutzer können ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Jede E-Mail enthält am Ende einen Abmeldelink, über den Nutzer ihre Einwilligung widerrufen können. Die Daten werden gelöscht, wenn sie für die genannten Zwecke nicht mehr benötigt werden und keine anderweitige Rechtsgrundlage für die Verarbeitung der Daten besteht.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">VI. Wie wir Daten erfassen und verarbeiten, wenn Nutzer mit uns Kontakt aufnehmen</h2>
                        <p>
                            Bei der Kontaktaufnahme über unser Kontaktformular oder andere Kommunikationskanäle (z.B. WhatsApp) verarbeiten wir personenbezogene Daten, soweit dies zur Beantwortung der Kontaktanfragen und der gewünschten Aktionen erforderlich ist. Diese Verarbeitung umfasst die Kontaktinformationen, die Nachrichten sowie die Kommunikationsdaten (z.B. Anfragezeiten) der Nutzer. Wir verarbeiten diese Daten, um unsere Nutzer zu unterstützen und auf Anfragen zu reagieren.
                        </p>
                        <p>
                            Die Rechtsgrundlage ist unser berechtigtes Interesse, die Anfragen der Nutzer effizient zu bearbeiten. Die Daten werden gelöscht, wenn sie für die genannten Zwecke nicht mehr benötigt werden und keine anderweitige Rechtsgrundlage für die Verarbeitung der Daten besteht. Dies ist in der Regel der Fall, wenn die Anfragen der Nutzer vollständig bearbeitet wurden und der jeweilige Sachverhalt keine weitere Speicherung der Daten erfordert.
                        </p>
                        <br />
                        
                        <h2 className="text-2xl font-semibold mt-4">VII. Wie wir “Cookies” verwenden</h2>
                        <p>
                        Wir verwenden „Cookies“, um unseren Nutzern auf unserer Internetseite eine Vielzahl von Funktionen zur Verfügung zu stellen und ihre Nutzererfahrung zu verbessern. Cookies sind kleine Textdateien, die über den Browser vorübergehend auf dem Endgerät des Nutzers gespeichert werden. Wenn Nutzer nicht möchten, dass wir Cookies verwenden, können sie ihre Browsereinstellungen entsprechend ändern. Bei einer vollständigen Deaktivierung der Verwendung von Cookies kann jedoch die Funktionalität der Internetseite beeinträchtigt sein.
                        </p>
                        <p>
                        Wir verwenden Cookies insbesondere in den folgenden Kategorien:
                        </p>
                        <ul>
                            <li>
                                <span style={{ textDecoration: 'underline'}}>Cookies für die Funktionsfähigkeit unserer Internetseite:</span> Diese Cookies sind unbedingt erforderlich, um das Funktionieren unserer Internetseite zu gewährleisten. Die Cookies dienen der Sicherheit, der reibungslosen Navigation und der Bereitstellung der wichtigsten Funktionen unserer Internetseite. 
                            </li>
                            <li>
                                <span style={{ textDecoration: 'underline'}}>Cookies für Marketing, Personalisierung, Analytik: </span>Wir verwenden Cookies zudem für statistische und analytische Zwecke, um unseren Service kontinuierlich zu verbessern. Außerdem verwenden wir sie, um unsere Internetseite durch Personalisierung für unsere Nutzer komfortabler zu gestalten sowie für Marketingmaßnahmen. Wir verwenden diese Cookies auf Grundlage einer ausdrücklichen Einwilligung unserer Nutzer. Unsere Nutzer können Cookies für die drei hier genannten Zwecke separat akzeptieren oder ablehnen.
                            </li>
                        </ul>
                        <p>
                        Wir verwenden sowohl Cookies, die bei Schließen des Browsers oder durch Ausloggen automatisiert gelöscht werden („transiente Cookies“), als auch Cookies, die automatisiert nach einer vorgegebenen Dauer gelöscht werden („persistente Cookies“). Sie können in den Einstellungen Ihres Browsers die gesetzten Cookies und die Laufzeiten jederzeit einsehen und die Cookies manuell löschen.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">VIII. Verpflichtung der Nutzer zur Bereitstellung von Daten</h2>
                        <p>
                            Grundsätzlich ist kein Nutzer verpflichtet, uns Daten zur Verfügung zu stellen. Die Nichtbereitstellung mancher personenbezogenen Daten (z.B. Vertragsdaten) hätte jedoch zur Folge, dass der Vertrag mit diesen Nutzern nicht geschlossen werden könnte. Bestimmte Informationen werden zudem bei Nutzung der Dienste automatisch erhoben (z.B. Technische Daten).
                        </p>
                        <br />

                        <h2  className="text-2xl font-semibold mt-4">IX. Übermittlung in Drittstaaten</h2>
                        <p>
                        Im Ausnahmefall können personenbezogene Daten der Nutzer in Drittländer übertragen werden, einschließlich den Vereinigten Staaten. Soweit personenbezogene Daten in Drittländer übertragen werden, werden wir die Einhaltung der Anforderungen der DSGVO und der jeweils anwendbaren Gesetze sicherstellen.
                        </p>
                        <p>
                        Bei Drittlandsübermittlungen in die USA verwenden wir grundsätzlich ausschließlich Anbieter, die unter dem EU-U.S. Data Privacy Framework lizenziert sind. Für Drittlandübermittlungen, die nicht auf einen Angemessenheitsbeschluss der Kommission gestützt werden können, stützen wir uns auf Standardvertragsklauseln.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">X. In welchen Konstellationen wir Daten an Dritte übermitteln</h2>
                        <p>
                        Personenbezogene Daten unserer Nutzer werden nicht zu anderen als den in dieser Datenschutzerklärung genannten Zwecken an Dritte weitergegeben.
                        </p>
                        <p>
                        Personenbezogene Daten unserer Nutzer werden nur dann an Dritte weitergegeben, wenn die Nutzer ausdrücklich in die Weitergabe eingewilligt haben, die Weitergabe zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist, oder wenn die Verarbeitung der Daten für die Erfüllung eines Vertrags, dessen Vertragspartei der Nutzer ist, erforderlich ist (siehe hierzu auch Abschnitt III.). Darüber hinaus geben wir Daten der Nutzer weiter, wenn dies zur Geltendmachung oder Verteidigung von Rechtsansprüchen oder zur Rechtsvertretung erforderlich ist und keine Anhaltspunkte bestehen, dass der Nutzer ein überwiegendes schutzwürdiges Interesse an der Geheimhaltung der Daten hat. Schließlich gewähren wir ausgewählten Dritten Zugang zu Daten der Nutzer, soweit wir diese Dritten zur Erbringung von einzelnen Dienstleistungen beauftragen, z.B. im Bereich des Marketings, der Bereitstellung von Inhalten und Funktionen bei unseren Diensten (z.B. Videofunktionen), Analysen, Datenspeicherung sowie Sicherheit.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">
                        XI. Recht der Nutzer auf Widerspruch gegen die Datenverarbeitung 
                        </h2>
                        <p>
                        Wenn wir personenbezogene Daten auf Grundlage eines berechtigten Interesses verarbeiten, haben die Nutzer das Recht, der Verarbeitung zu widersprechen, sofern es dafür Gründe gibt, die sich aus der besonderen Situation der Nutzer ergeben. Wir verarbeiten personenbezogene Daten der Nutzer dann nicht mehr, es sei denn, es bestehen zwingende schutzwürdige Gründe für die Verarbeitung, die die Interessen, Rechte und Freiheiten der Nutzer überwiegen, oder die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
                        </p>
                        <p>
                        Wenn Nutzer von ihrem Widerspruchsrecht Gebrauch machen wollen, reicht eine E-Mail an uns aus.
                        </p>
                        <br />

                        <h2 className="text-2xl font-semibold mt-4">XII. Weitere Rechte, die Nutzer als betroffene Personen haben </h2>
                        <p>
                        Als betroffene Person haben unsere Nutzer nach der DSGVO verschiedene Rechte. Nutzer haben insbesondere das Recht, Auskunft über ihre personenbezogenen Daten zu erhalten. Darüber hinaus haben Nutzer ein Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung sowie auf Datenübertragbarkeit, soweit ihnen dies gesetzlich zusteht. 
                        </p>
                        <p>
                        Wenn Nutzer uns eine Einwilligung zur Datenverarbeitung erteilen, können sie diese Einwilligung jederzeit widerrufen. Der Widerruf der Einwilligung berührt nicht die Rechtmäßigkeit der Verarbeitung, die vor Ausübung des Widerrufs auf Grundlage der Einwilligung vorgenommen wurde.
                        </p>
                        <p>
                        Darüber hinaus haben die Nutzer das Recht, bei der zuständigen Aufsichtsbehörde eine Beschwerde über die Verarbeitung personenbezogener Daten durch uns einzureichen.
                        </p>
                        <p>
                        Eine automatisierte Entscheidungsfindung im Sinne des GDPR findet nicht statt.
                        </p>
                        <p className="mt-8">Datum: Oktober 2024</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Datenschutz;
