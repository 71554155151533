import "react-circular-progressbar/dist/styles.css";
import { Chart } from "react-google-charts";

interface CircularChartProps {
  rente: number;
  gap: number;
}

const CircularChart = ({ rente, gap }: CircularChartProps) => {

  const data = [
    ["Task", "Hours per Day"],
    ["Rente", rente],
    ["Rentenlücke", gap]
  ];
  
  const options = {
    title: "",
    is3D: false,
    legend: "none",
    pieHole: 0.8,
    pieSliceText: "none",
    slices: {
      0: { color: '#C478FE' },
      1: { color: '#7C4EFB' },
    },
  };

  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"200px"}
    />
  );
};

export default CircularChart;
