import Analytics from "../assets/chartIcon.png";
import downloadIcon from "../assets/download.png";
import faceID from "../assets/face-id.png";
import Liner from "../assets/Line 8.png";
import IPhone from "../assets/screenStepsSections.png";

const Planning = () => {
  return (
    <section className="min-h-screen flex justify-center py-8 px-4">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
              <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
                In unter 10 Minuten loslegen.
              </h2>
            <div className="flex flex-col mt-6">
              {/* Step 1 */}
              <div className="flex items-start gap-4">
                <div className="flex flex-col items-center">
                  <div style={{ width: 70, height: 70 }} className="border border-[#7C4EFB] flex items-center justify-center p-4 rounded-full">
                    <img
                      src={downloadIcon}
                      className="object-cover"
                      alt="Altersrente mit ETFs aufbauen"
                      style={{ width: 50}}
                      loading="lazy"
                    />
                  </div>
                  <img src={Liner} className="h-12" alt="Liner" loading="lazy"/>
                </div>
                <p className="sm:leading-7 font-normal sm:text-[18px] text-[13px] leading-6">
                  Lade dir pensionflow im App Store oder bei Google Play herunter
                </p>
              </div>

              {/* Step 2 */}
              <div className="flex items-start gap-4">
                <div className="flex flex-col items-center">
                  <div style={{ width: 70, height: 70 }} className="border border-[#7C4EFB] flex items-center justify-center p-4 rounded-full">
                    <img
                      src={faceID}
                      className="object-cover"
                      alt="Altersrente mit ETFs aufbauen"
                      style={{ width: 50 }}
                      loading="lazy"
                    />
                  </div>
                  <img src={Liner} className="h-12" alt="Liner" loading="lazy"/>
                </div>
                <p className="sm:leading-7 font-normal sm:text-[18px] text-[13px] leading-6 mt-3">
                  Registriere und identifiziere dich direkt in der App
                </p>
              </div>

              {/* Step 3 */}
              <div className="flex items-start gap-4">
                <div className="flex flex-col items-center">
                  <div style={{ width: 70, height: 70 }} className="border border-[#7C4EFB] flex items-center justify-center p-4 rounded-full">
                    <img
                      src={Analytics}
                      className="object-cover"
                      alt="Altersrente mit ETFs aufbauen"
                      width={50}
                      loading="lazy"
                    />
                  </div>
                </div>
                <p className="sm:leading-7 font-normal sm:text-[18px] text-[13px] leading-6 mt-4">
                  Starte heute noch mit deiner Altersvorsorge
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-end">
            <img src={IPhone} className="w-36 md:w-42 lg:w-[230px]" alt="Altersvorsorge per App" loading="lazy"/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Planning;
