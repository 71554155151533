import { Fragment, useState } from "react";
//import { Check, X } from "lucide-react";
//import Logo from "../assets/pensionflow_logo_transparent 2.png";
import { Dialog, Transition } from "@headlessui/react";
import Image from '../assets/comparisonTable.png';

const ETF = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="container min-h-screen flex justify-center">
      <section className="grid grid-cols-1 md:grid-cols-2 gap-[32px] items-center py-8 px-4">
          <div className="flex flex-col justify-center">
            {/* Short title for mobile */}
            <h2 className="lg:hidden font-bold poppins-bold text-[24px] leading-[31.2px]">
              Rentenversicherung vs. ETF-Sparplan.
            </h2>
            {/* Long title for larger screens */}
            <h2 className="hidden lg:block font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
              ETF-Rentenversicherung vs. ETF-Sparplan.
            </h2>
            <div className="mt-[32px] flex flex-col gap-5">
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Maßgeschneidert auf die Altersvorsorge. </strong> 
                Eine ETF-Rentenversicherung vereint die Vorteile eines kostengünstigen und flexiblen ETF-Sparplans mit denen einer klassischen Rentenversicherung.
              </p>
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">12/62-Regelung. </strong>
                Wenn du mindestens 12 Jahre lang eingezahlt hast und 62 Jahre wirst, profitierst du von erheblichen Steuervorteilen.
                Im Gegensatz zu ETF-Sparplänen zahlst du auf regelmäßige Auszahlungen kaum Steuern und die Kapitalauszahlung
                wird nur mit dem halben Steuersatz besteuert. Hier ein <span onClick={openModal} className="text-[#7C4EFB]" style={{ cursor: 'pointer' }}>Beispiel.</span>
              </p>{" "}
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Kein oder, sondern und. </strong> 
                Ein ETF-Sparplan eignet sich für den Vermögensaufbau, eine ETF-Rentenversicherung für eine lebenslange, planbare Rente. Oft ist es sinnvoll, beides zu kombinieren.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img 
              src={Image} 
              className="w-full md:w-42 lg:w-[450px]"
              alt="Vorteile ETF-Rentenversicherung"
              loading="lazy"
            />
          </div>
      </section>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full flex items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-16">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Die 12/62-Regel
                  </Dialog.Title>
                  <div className="mt-2">
                    <div>
                      <p>Bei einer ETF-Rentenversicherung kommt die 12/62-Regel zur Anwendung. Das bedeutet, dass du von erheblichen Steuervorteilen gegenüber 
                        einem herkömmlichen ETF-Sparplan profitierst, wenn du mindestens 12 Jahre lang einzahlst und 62 Jahre alt bist.
                      </p>
                      <br></br>
                      <strong className="underline">Einmalige Auszahlung:</strong>
                      <p>Hier greift das Halbeinkünfteverfahren. Die Hälfte des Gewinns bleibt steuerfrei, während die andere Hälfte mit dem 
                        persönlichen Steuersatz versteuert wird. Zudem gilt vorab eine Teilfreistellung von 15%. Das bedeutet, dass 15% des Gewinns 
                        komplett steuerfrei sind. Betrachten wir ein Beispiel mit einem Gewinn von 100.000 € und einem persönlichen Grenzsteuersatz von 30%:
                      </p>
                      <br></br>
                      <ul className="list-disc ml-5">
                        <li>100.000 € Gewinn x 0,85 (nach Teilfreistellung) = 85.000 €</li>
                        <li>85.000 € x 0,5 (die Hälfte ist steuerfrei) = 42.500 €</li>
                        <li>42.500 € x 0,3 (beispielhafter Steuersatz) = 12.750 € Steuer</li>
                      </ul>
                      <br></br>
                      <strong className="underline">Monatliche Rente:</strong>
                      <p>
                        Hier wird das Ertragswertverfahren angewendet. Der zu versteuernde Anteil der Rente (Ertragsanteil) hängt vom Alter bei Rentenbeginn ab. 
                        Wenn du zum Beispiel mit 67 Jahren die Rente erstmals in Anspruch nimmst, sind nur 17% der Rente zu versteuern. Bei einem persönlichen 
                        Steuersatz von 30% und einer monatlichen Rente von 1000 € ergibt sich folgende Berechnung:
                      </p>
                      <br></br>
                      <ul className="list-disc ml-5">
                        <li>1000 € x 0,17 (Ertragsanteil) = 170 €</li>
                        <li>170 € x 0,3 (persönlicher Steuersatz) = 51 € Steuer</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                      style={{ backgroundColor: "rgba(0,0,0,0.1)", color: 'rgb(0,0,0)' }}
                    >
                      Schließen
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ETF;