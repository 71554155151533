import AlteLeipziger from "../assets/AL_Logo_rot.png";
import Amundi from "../assets/amundi.png";
import BlackRock from "../assets/ISH_eng_black_rgb.png";
//import Em from "../assets/em.png";
//import Europe from "../assets/europe.png";
//import Global from "../assets/global.png";
//import Jp from "../assets/jp.png";
import StateStreet from "../assets/SPDR_Reg_size2_Letterhead_horizontal_RGB.jpg";
//import Ubs from "../assets/ubs.png";
//import Vanguard from "../assets/vanguard.png";
//import Us from "../assets/us.png";
import { useEffect, useState } from "react";

const StrongPartner = () => {
  const radius = 90;
  const circumference = 2 * Math.PI * radius;

  const images = [
    Amundi, 
    AlteLeipziger,
    BlackRock, 
    //Em, 
    //Europe, 
    //Global, 
    //Jp, 
    StateStreet,
    //Ubs,
    //Vanguard, 
    //Us
  ];
  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => {
        const currentIndex = images.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % images.length; // Loop back to the first image after the last one
        return images[nextIndex];
      });
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <section className="bg-[#F3F1FF] min-h-screen flex justify-center">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[32px] items-center py-8 px-4">
          <div >
            <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
              Starke Partner an deiner Seite.
            </h2>
            <div className="mt-[32px] space-y-[20px]">
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Alte Leipziger. </strong> 
                Gemeinsam mit dem 1830 gegründeten führenden Versicherer wurde Pensionflow für dich entwickelt: eine digitale, einfache, verständliche und sichere Altersvorsorge.
              </p>
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Dein Guthaben ist sicher. </strong>
                Die finanzstarke Alte Leipziger verwahrt dein Vermögen und ist auch im Insolvenzfall durch den gesetzlichen Sicherungsfonds für Lebensversicherer geschützt.
              </p>{" "}
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Die besten und kostengünstigsten ETFs. </strong>{" "}
                Pensionflow arbeitet mit den größten Anbietern zusammen und hat für dich renditestarke und kostengünstige ETF-Strategien entwickelt.
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div
              className="relative sm:w-[300px] sm:y[300px] lg:w-[400px] lg:h-[400px]"
              style={{
                backgroundColor: 'white',
                borderRadius: '50%',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <svg className="w-full h-full" viewBox="0 0 200 200">
                <circle
                  className="text-[#4C39EE] stroke-current"
                  strokeWidth="5"
                  stroke="currentColor"
                  fill="transparent"
                  r="90"
                  cx="100"
                  cy="100"
                />
                <circle
                  className="text-[#C478FE] stroke-current"
                  strokeWidth="5"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r="90"
                  cx="100"
                  cy="100"
                  style={{
                    strokeDasharray: `${circumference * 0.25} ${circumference}`,
                    strokeDashoffset: `${circumference * 0.75}`,
                  }}
                />
                <circle
                  className="text-[#D9D5FC] stroke-current"
                  strokeWidth="5"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r="90"
                  cx="100"
                  cy="100"
                  style={{
                    strokeDasharray: `${circumference * 0.25} ${circumference}`,
                    strokeDashoffset: `${circumference * 0.5}`,
                  }}
                />
                <circle
                  className="text-[#4C39EE] stroke-current"
                  strokeWidth="5"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r="90"
                  cx="100"
                  cy="100"
                  style={{
                    strokeDasharray: `${circumference * 0.25} ${circumference}`,
                    strokeDashoffset: `${circumference * 0.25}`,
                  }}
                />
                <circle
                  className="text-[#C478FE] stroke-current"
                  strokeWidth="5"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r="90"
                  cx="100"
                  cy="100"
                  style={{
                    strokeDasharray: `${circumference * 0.25} ${circumference}`,
                    strokeDashoffset: `0`,
                  }}
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <img src={currentImage} className="w-[200px] lg:w-[250px]" alt="Altersrente mit ETFs aufbauen" loading="lazy"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StrongPartner;
