import { faqItems } from "../utils/faqList";
import { ChevronDown, ChevronRight, Minus, Plus } from "lucide-react";
import { forwardRef, useState } from "react";

interface FaqItemProps {
  question: string;
  answer: string | JSX.Element;
  isActive: boolean;
  onClick: () => void;
}

const FaqItem = ({ question, answer, isActive, onClick }: FaqItemProps) => (
  <div className="flex justify-between items-start cursor-pointer" onClick={onClick}>
    <div className="flex-1">
      <p className="font-bold">{question}</p>
      {isActive && (
        <div className="pt-2">
          <p>{answer}</p>
        </div>
      )}
    </div>
    <div className="flex-shrink-0 ml-4">
      {isActive ? (
        <Minus className="w-6 h-6 text-[#7C4EFB] cursor-pointer" />
      ) : (
        <Plus className="w-6 h-6 text-[#7C4EFB] cursor-pointer" />
      )}
    </div>
  </div>
);

interface FaqCategoryProps {
  category: string;
  questions: { question: string; answer: string | JSX.Element }[];
  isActive: boolean;
  onClick: () => void;
  activeIndex: string | null;
  setActiveIndex: (index: string | null) => void;
}

const FaqCategory = ({category, questions, isActive, onClick, activeIndex, setActiveIndex}: FaqCategoryProps) => (
  <div className="space-y-5">
    <div className="flex justify-between items-center cursor-pointer" onClick={onClick}>
      <h2 className="font-bold text-[#7C4EFB] sm:text-[25px] text-[22px]">{category}</h2>
      <div className="flex-shrink-0 ml-4">
        {isActive ? (
          <ChevronDown className="w-6 h-6 text-[#7C4EFB] cursor-pointer" />
        ) : (
          <ChevronRight className="w-6 h-6 text-[#7C4EFB] cursor-pointer" />
        )}
      </div>
    </div>
    {isActive && (
      <div className="space-y-4">
        {questions.map((item, qIndex) => (
          <FaqItem
            key={qIndex}
            question={item.question}
            answer={item.answer}
            isActive={activeIndex === `${category}-${qIndex}`}
            onClick={() =>
              setActiveIndex(activeIndex === `${category}-${qIndex}` ? null : `${category}-${qIndex}`)
            }
          />
        ))}
      </div>
    )}
  </div>
);

const Faq = forwardRef<HTMLDivElement>((_, ref) => {
  const [activeIndex, setActiveIndex] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  

  return (
    <div id="faq" ref={ref} className="bg-[#F3F1FF] min-h-[500px] flex justify-center">
      <div className="container">
        <div className="grid grid-cols-1 items-center py-8 px-4">
          <div className="">
            <h2 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
              FAQ
            </h2>
          </div>
          <div className="">
            <section className=" mt-[32px] grid grid-cols-1 md:grid-cols-2 gap-10">
              {faqItems.map((faq, index) => (
                <FaqCategory
                  key={index}
                  category={faq.category}
                  questions={faq.questions}
                  isActive={activeCategory === faq.category}
                  onClick={() => setActiveCategory(activeCategory === faq.category ? null : faq.category)}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              ))}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Faq;
