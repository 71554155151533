import React from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Chart } from "react-google-charts";
import RenditeRisikoComp from './components/RenditeRisikoComp';

const StrategyInfo = () => {
    const dataZielorientiert = [
        ["Land", "Prozent"],
        ["USA", 50],
        ["Europa", 25],
        ["Japan", 5],
        ["EM", 20],
    ];
    const dataStabilitet = [
        ["Land", "Prozent"],
        ["USA", 40],
        ["Europa", 20],
        ["Japan", 4],
        ["EM", 16],
        ["Anleihen", 20],
    ];
    const dataFlexibilität = [
        ["Land", "Prozent"],
        ["USA", 30],
        ["Europa", 15],
        ["Japan", 3],
        ["EM", 12],
        ["Anleihen", 40],
    ];
    const options = {
        colors: ["#7a47fe", "#ae49c2", "#6db8f9", "#4473c5", "#6c757d"],
        legend: {
            position: "bottom",
            alignment: "center",
            textStyle: {
                color: "#233238",
                fontSize: 11,
            },
        },
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Navbar isOnHome={false} />

            <div className="container mx-auto py-10 md:py-28">
                <div className="text-center mb-10 md:mb-10">
                    <h1 className="font-bold text-2xl sm:text-4xl leading-snug mb-6 sm:px-10 xl:px-36">
                        Wähle aus drei renditestarken Anlagestrategien.
                    </h1>
                    <p className="text-base sm:text-lg leading-6 sm:leading-7 text-gray-600">
                        Du bestimmst selbst, wie viel Risiko Du eingehen möchtest. Pensionflow bietet Dir drei globale
                        Anlagestrategien mit unterschiedlichen Aktienanteilen: 60% Aktienanteil steht für ein geringeres Risiko,
                        während 100% Aktienanteil die höchsten Renditechancen bietet. Ergänzt wird Deine Anlage durch Anleihen, die
                        Stabilität bieten und regelmäßige Zinserträge generieren. Möchtest Du nachhaltig investieren? Unsere
                        Strategien sind auch als ESG-Variante erhältlich.
                    </p>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    {[
                        {
                            title: "Zielorientiert",
                            rendite: "7",
                            data: dataZielorientiert,
                            description: `Dieses Portfolio verfolgt eine renditeorientierte Strategie und investiert zu 100% in ein 
                            global diversifiziertes Aktienportfolio. Die Strategie ist besonders für langfristig orientierte Anleger 
                            mit hoher Risikobereitschaft geeignet, da sie über eine längere Haltedauer das Potenzial für maximale Rendite bietet.`,
                            dots: ["lila_dot", "lila_dot", "lila_dot"],
                        },
                        {
                            title: "Stabilität",
                            rendite: "6",
                            data: dataStabilitet,
                            description: `Dieses ausgewogene Portfolio kombiniert 80% global gestreute Aktien mit 20% Anleihen als Beimischung. 
                            Die Anleihen dienen dazu, das Risiko zu senken, da sie in der Regel weniger schwankungsanfällig sind. 
                            Die Strategie bietet einen ausgewogenen Mix aus Rendite und Risiko und ist besonders für Anleger geeignet, 
                            die Wert auf Stabilität legen.`,
                            dots: ["lila_dot", "lila_dot", "gray_dot"],
                        },
                        {
                            title: "Flexibilität",
                            rendite: "5",
                            data: dataFlexibilität,
                            description: `Dieses Portfolio mit einer Allokation von 60% in global diversifizierte Aktien sowie 40% Anleihen strebt danach, starke Wertschwankungen zu vermeiden. 
                            Die Strategie ist besonders für konservative Anleger geeignet, einschließlich jener, die sich noch unschlüssig sind, ob sie kurz- bis mittelfristig Zugriff 
                            auf ihr angespartes Guthaben benötigen.`,
                            dots: ["lila_dot", "gray_dot", "gray_dot"],
                        },
                    ].map((strategy, index) => (
                        <div key={index} className="flex flex-col items-center text-center">
                            <p className="text-lg sm:text-xl font-semibold text-[#7C4EFB]">
                                <span className="text-black">Weltportfolio</span> <span className="font-bold">{strategy.title}</span>
                            </p>
                            <div className="relative w-full mt-6">
                                <div className="absolute z-10 w-full top-5 left-1/2 transform -translate-x-1/2">
                                    <RenditeRisikoComp rendite={strategy.rendite} dots={strategy.dots.map(dot => require(`../../assets/${dot}.png`))} />
                                </div>
                                <Chart chartType="PieChart" data={strategy.data} options={options} width="100%" height="400px" />
                            </div>
                            <p className="mt-4 text-sm sm:text-base leading-6 text-gray-600">{strategy.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default StrategyInfo;
